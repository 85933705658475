/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

// You can delete this file if you're not using it
import { loadStripe } from "@stripe/stripe-js"
import React, { useState } from "react"
import createPersistedState from "use-persisted-state"
import { CartProvider } from "use-shopping-cart"
import AppContext from "./src/AppContext"
import "./src/css/index.css"
import { getEnvKey } from "./functions/helper"

require("typeface-palanquin")
require("typeface-palanquin-dark")

const stripeKey = getEnvKey(
  process.env.GATSBY_DEV_STRIPE_PUBLIC_KEY,
  process.env.GATSBY_STRIPE_PUBLIC_KEY
)

const stripePromise = loadStripe(stripeKey)
const usePrefersPickupState = createPersistedState("prefersPickup")

const Provider = (props) => {
  const [loading, setLoading] = useState(false)
  const [prefersPickup, setPrefersPickup] = usePrefersPickupState(false)

  return (
    <AppContext.Provider
      value={{
        loading,
        setLoading,
        prefersPickup,
        setPrefersPickup,
      }}
    >
      <CartProvider
        stripe={stripePromise}
        mode="checkout-session"
        successUrl={`http://localhost:8888/success/`}
        cancelUrl={`http://localhost:8888/kurv`}
        currency="DKK"
        allowedCountries={["DK"]}
        billingAddressCollection={true}
      >
        {props.children}
      </CartProvider>
    </AppContext.Provider>
  )
}

export const wrapRootElement = ({ element }) => <Provider>{element}</Provider>
