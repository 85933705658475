import React from "react"

interface AppContextType {
  loading: boolean
  setLoading: (isLoading: boolean) => void
}

const contextNotLoaded = (input: boolean) =>
  console.log("app context ikke loaded korrekt endnu")

const AppContext = React.createContext<AppContextType>({
  loading: false,
  setLoading: contextNotLoaded,
})

export default AppContext
